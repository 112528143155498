<template>
  <!-- 首页 -->
  <div class="container" style="position:relative">
    <!-- <Popup></Popup> -->
    <header_public :navindex="0"></header_public>

    <div class="mybanner swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img class="swiper-banner-img" src="../../assets/images/sy/bannerNewOne.jpg" alt="高薪资" />
        </div>
        <div class="swiper-slide">
          <img
            class="swiper-banner-img"
            src="../../assets/images/sy/bannerNewTwo.jpg"
            alt="高薪资"
          />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="text1 text11" style>
      课程近期
      <span style="color: #f14549;">开课时间</span>
    </div>
    <div class="kktime">
      <div class="swiper-wrapper">
        <div
          class="kcitem swiper-slide swiper-no-swiping"
          v-for="(item, index) in kctext"
          :key="index"
        >
          <div class="kc_city">{{ item[0] }}</div>
          <div class="kc_text">{{ item[1] }} {{ item[2] }}开班</div>
          <img class="kc_hot" src="../../assets/images/sy/fire.png" alt="开班" />
          <div class="kc_btn" @click="advice">预约报名</div>
        </div>
      </div>
    </div>
    <!-- 课程模块 新增 -->
    <div class="mokuai">
      <div class="text1 text11" style>行业热门数字技术课程</div>
      <div class="text2">针对不同基础学员量身打造 ,保持课程内容的先进性和实用性</div>
      <!-- <div class="text2"></div> -->

      <div class="mkbg">
        <div class="mokuai_box">
          <div class="mokuai_box_item" @mouseenter="hoverItem(2)" @mouseleave="outItem()">
            <div class="mokuai_img_box" @click="todesgin">
              <p class="mokuai_box_title">AI短视频大师班</p>
              <img class="mokuai_img_text" src="../../assets/images/sy/shijuesheji.png" />
              <img class="mokuai_img" src="../../assets/images/sy/shijuesheji_bg.png" />
            </div>
            <div class="mokuai_box_item_text">
              <p>全面融入AIGC技术</p>
              <p>人工智能驱动式教学</p>
              <p class="timeText">开班时间</p>
              <p class="dateText">
                2023-{{ kcdatelist[0] }}日开课
                <span class="hottext">预约报名</span>
              </p>
            </div>
            <div
              class="mokuai_show_item"
              v-show="itemId == 2"
              @click="todesgin"
            >互联网及5G快速发展,视频爆炸时代已来临,人才需求呈指数级增长,就业路径宽、薪资待遇高，短视频无处不在</div>
          </div>

          <div class="mokuai_box_item" @mouseenter="hoverItem(5)" @mouseleave="outItem()">
            <div class="mokuai_img_box" @click="topython">
              <p class="mokuai_box_title">人工智能</p>
              <img class="mokuai_img_text" src="../../assets/images/sy/rengongzhineng.png" />
              <img class="mokuai_img" src="../../assets/images/sy/rengongzhineng_bg.png" />
            </div>
            <div class="mokuai_box_item_text">
              <p>简单易学上手快</p>
              <p>学成之后好就业</p>
              <p class="timeText">开班时间</p>
              <p class="dateText">
                2023-{{ kcdatelist[0] }}日开课
                <span class="hottext">预约报名</span>
              </p>
            </div>
            <div
              class="mokuai_show_item"
              v-show="itemId == 5"
              @click="topython"
            >人工智能简单易学炙手可热，非常具有竞争力，且应用领域非常广泛，各技术方向岗位的人才极度稀缺</div>
          </div>

          <div class="mokuai_box_item" @mouseenter="hoverItem(1)" @mouseleave="outItem()">
            <div class="mokuai_img_box" @click="tojava">
              <p class="mokuai_box_title">Java大数据</p>
              <img class="mokuai_img_text" src="../../assets/images/sy/javadashuju.png" />
              <img class="mokuai_img" src="../../assets/images/sy/javadashuju_bg.png" />
            </div>
            <div class="mokuai_box_item_text">
              <p>0基础挑战"薪"高度</p>
              <p>职场不设限</p>
              <p class="timeText">开班时间</p>
              <p class="dateText">
                2023-{{ kcdatelist[0] }}日开课
                <span class="hottext">预约报名</span>
              </p>
            </div>
            <div
              class="mokuai_show_item"
              v-show="itemId == 1"
              @click="tojava"
            >Java语言应用广泛是行业主流,现在80%互联网公司基本都在使用Java,Java工程师就业薪资高,未来发展好</div>
          </div>

          <!-- <div class="mokuai_box_item" @mouseenter="hoverItem(3)" @mouseleave="outItem()">
            <div class="mokuai_img_box" @click="toquanzhan">
              <p class="mokuai_box_title">全栈开发</p>
              <img class="mokuai_img_text" src="../../assets/images/sy/quanzhankaifa.png" />
              <img class="mokuai_img" src="../../assets/images/sy/quanzhankaifa_bg.png" />
            </div>
            <div class="mokuai_box_item_text">
              <p>企业争抢人才</p>
              <p>职业生涯快发展</p>
              <p class="timeText">开班时间</p>
              <p class="dateText">
                2023-{{ kcdatelist[0] }}日开课
                <span class="hottext">预约报名</span>
              </p>
            </div>
            <div
              class="mokuai_show_item"
              v-show="itemId == 3"
              @click="toquanzhan"
            >全栈工程师既可胜任前端也可胜任后端,是一位全能型技术人才,更匹配企业的用人需求,晋升机会和薪酬待遇也随之上升</div>
          </div>-->

          <!-- <div class="mokuai_box_item" @mouseenter="hoverItem(4)" @mouseleave="outItem()">
            <div class="mokuai_img_box" @click="toyunjisuan">
              <p class="mokuai_box_title">前端开发</p>
              <img class="mokuai_img_text" src="../../assets/images/sy/qianduanyunjisuan.png" />
              <img class="mokuai_img" src="../../assets/images/sy/qianduanyunjisuan_bg.png" />
            </div>
            <div class="mokuai_box_item_text">
              <p>入学门槛低</p>
              <p>短期技术快速学习</p>
              <p class="timeText">开班时间</p>
              <p class="dateText">
                2023-{{ kcdatelist[0] }}日开课
                <span class="hottext">预约报名</span>
              </p>
            </div>
            <div
              class="mokuai_show_item"
              v-show="itemId == 4"
              @click="toyunjisuan"
            >Web前端工程师入门相对简单，人才缺口大、岗位需求迫切、应用领域众多，是零基础学习IT开发的首选语言</div>
          </div>-->

          <div class="mokuai_box_item" @mouseenter="hoverItem(6)" @mouseleave="outItem()">
            <div class="mokuai_img_box" @click="toceshi">
              <p class="mokuai_box_title">新媒体影视</p>
              <img
                class="mokuai_img_text"
                style="margin-top: 0.875rem;"
                src="../../assets/images/sy/ceshi.png"
              />
              <img class="mokuai_img" src="../../assets/images/sy/ceshi_bg.png" />
            </div>
            <div class="mokuai_box_item_text">
              <p>锁定短剧赛道</p>
              <p>培养新时代编导</p>
              <p class="timeText">开班时间</p>
              <p class="dateText">
                2023-{{ kcdatelist[0] }}日开课
                <span class="hottext">预约报名</span>
              </p>
            </div>
            <div
              class="mokuai_show_item"
              v-show="itemId == 6"
              @click="toceshi"
            >微短剧，是短视频行业一颗闪亮的内容新星，积云教育迅速定位赛道、拥抱时代焦点，培养精通“编/导/拍/剪/包装/制作”全流程的新媒体影视人才</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 输入电话号 新增 -->
    <div class="index-phone_box">
      <div class="text1" style>
        培训费用多少钱
        <span style="color: #f14549;">评估一下</span>
      </div>
      <div class="text2">根据个人情况，评估下学习费用，心里有底</div>
      <pinggu></pinggu>
    </div>

    <!-- 新增 CBE -->
    <div class="cbe">
      <div class="text1" style>
        <span style="color: #f14549;">CBE教学模式：</span>以能力培养为中心
      </div>
      <div class="text2">CBE教学模式即"以能力培养为中心的教学体系"，它强调的是职业或岗位所需能力的确定、学习、掌握和运用。</div>
      <div class="text2">也就是以职业或岗位所必须的知识、技能、行为意识为从业能力的基础，以能力表现为教学目标，教学进程的基准。</div>
      <div class="text2">CBE教学模式更适合经济和社会发展的需要，是目前一种较先进的教学模式。</div>
      <div class="cbebox">
        <div class="cbe1">
          <div class="cbe11">
            <div>
              <div v-for="(item, index) in moshi[0]" :key="index">{{ item.text }}</div>
            </div>
            <div>
              <div
                v-for="(item, index) in moshi[1]"
                :key="index"
                :class="index % 2 == 0 ? 'color1' : ''"
              >{{ item.text }}</div>
            </div>
            <div>
              <div
                v-for="(item, index) in moshi[2]"
                :key="index"
                :class="index % 2 == 0 ? 'color1' : ''"
              >{{ item.text }}</div>
            </div>
          </div>
          <div class="vs">
            <div>CBE教学模式</div>
            <div></div>
            <div>传统教学模式</div>
          </div>
        </div>
        <div class="baoz" @click="advice">如何让你能学会</div>
      </div>
    </div>
    <!-- 新增 教学循环 -->

    <div class="cbeImgbox">
      <div class="text1" style>
        <span style="color: #f14549;">
          <strong>CBE教学体系：</strong>
        </span>教学PDCA循环
      </div>
      <div class="cbeimg">
        <img class="cbe-zhuan" src="../../assets/images/sy/cbe-zhaun.png" />
        <img class="cbe-shan" src="../../assets/images/sy/cbe-shan.png" />
        <img class="cbe-bg" src="../../assets/images/sy/cbe-bg.png" />
      </div>
    </div>

    <div class="baoz" @click="advice">测一测自主学习能力</div>
    <!-- 新增 就业能力 -->
    <div class="nengli">
      <div class="text1" style>
        <span style="color: #f14549;">
          <strong>CBE教学目标：</strong>
        </span>综合能力
      </div>

      <div class="nengliBox">
        <div class="nenglilist">
          <div v-for="(item, index) in nengli" :key="index">
            <div class="bgimgs" :style="{ background: 'url(' + item.img + ')' }">
              <div>
                <div>{{ item.text1 }}</div>
                <div>{{ item.text2 }}</div>
                <div>{{ item.text3 }}</div>
                <div>{{ item.text4 }}</div>
              </div>
              <div>{{ item.btntext }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增 讲师轮播图 -->
    <div>
      <div class="text1" style="margin-top:60px;">
        <span style="color: #f14549;">
          <strong>优秀的讲师，优秀的团队</strong>
        </span>，铸就优秀的你
      </div>
      <div class="text2">建立专职课研团队，源源不断引进企业技术专家，保障项目研发及课程设计科学、先进且贴和市场需求</div>

      <div class="bottom_text" v-if="true" style=" ">
        <!-- 轮播图 -->
        <div class="bannerWrap" style="width: ;border:0px solid red;overflow: hidden;margin:0 auto">
          <!--style="width:100%;height:300px;" -->
          <!-- Swiper -->
          <div
            style="width: 100rem;height:30rem;overflow: hidden;border:0px solid red"
            class="swiper-container teacher-con"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide teachers_box"
                v-for="(teacher, index) in teacherlist"
                :key="index"
              >
                <a href="javascript:void(0)">
                  <img :src="teacher.image ? teacher.image : ''" alt="管理团队_积云教育" title="管理团队_积云教育" />
                </a>
                <div class="teacherText">
                  <span>{{ teacher.teacherName }}</span>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <!-- <div  style="margin-top: 50px !important;" class="swiper-pagination swiper-pagination-white major-spit teacher-spit" id="swiper-spit"></div> -->
          </div>
          <!-- <div
			      class="swiper-button-next swiper-button-white teachers-switch-next  "
			    ></div>
			    <div
			      class="swiper-button-prev swiper-button-white teachers-switch-prev "
          ></div>-->
        </div>
        <div class="baoz" @click="advice">试学一下</div>
        <!-- Add Arrows -->
      </div>
    </div>

    <!-- 新增 薪资展示 -->
    <div>
      <div class="text1">
        拥有好技术
        <span style="color: #f14549;">
          <strong>成就好未来</strong>
        </span>
      </div>
      <!-- 薪资轮播图 -->

      <div style="margin-top: 60px;">
        <xinzilist></xinzilist>
      </div>
      <div class="baoz" @click="advice">如何提升专业能力</div>
    </div>

    <!-- 新增 校园环境 -->
    <div>
      <div class="text1" style="margin-top:50px;margin-bottom:60px;">
        积云教育
        <span style="color: #f14549;">“大学校园”</span>学习环境
      </div>
      <huanjing></huanjing>
    </div>
    <div class="baoz" @click="advice">预约到校参观</div>
    <!-- 新增 新闻动态 -->
    <div class="dongtai">
      <!-- 积云动态 -->
      <div class="dtList">
        <div class="dtitle">
          <span>积云动态</span>
          <img
            @click="toNewList(0)"
            class="dtListImg"
            src="../../assets/images/newlist/more.png"
            alt
          />
        </div>
        <div class="dt1"></div>
        <div class="dtitem">
          <div v-for="(item, index) in newslist" :key="index">
            <a :href="'https://www.usian.cn/news_detail/' + item.id + '?type=0'" target="_blank">
              <div class="dtitem_dot_box">
                <div class="dtitem_dot"></div>
              </div>
              <div class="pointer dtitem_text">{{ item.title }}</div>
              <div class="pointer">{{ formatDate(new Date(item.createtime * 1000)) }}</div>
            </a>
          </div>
        </div>
      </div>
      <!-- 行业动态 -->
      <div class="dtList">
        <div class="dtitle">
          <span>行业动态</span>
          <img
            @click="toNewList(1)"
            class="dtListImg"
            src="../../assets/images/newlist/more.png"
            alt
          />
        </div>
        <div class="dt2"></div>
        <div class="dtitem">
          <div v-for="(item, index) in newslist2" :key="index">
            <a :href="'https://www.usian.cn/news_detail/' + item.id + '?type=1'" target="_blank">
              <div class="dtitem_dot_box">
                <div class="dtitem_dot"></div>
              </div>
              <div class="pointer dtitem_text">{{ item.title }}</div>
              <div class="pointer">{{ formatDate(new Date(item.createtime * 1000)) }}</div>
            </a>
          </div>
        </div>
      </div>
      <!-- 教学动态 -->
      <div class="dtList">
        <div class="dtitle">
          <span>教学动态</span>
          <img
            @click="toNewList(2)"
            class="dtListImg"
            src="../../assets/images/newlist/more.png"
            alt
          />
        </div>
        <div class="dt3"></div>
        <div class="dtitem">
          <div v-for="(item, index) in newslist3" :key="index">
            <a :href="'https://www.usian.cn/news_detail/' + item.id + '?type=2'" target="_blank">
              <div class="dtitem_dot_box">
                <div class="dtitem_dot"></div>
              </div>
              <div class="pointer dtitem_text">{{ item.title }}</div>
              <div class="pointer">{{ formatDate(new Date(item.createtime * 1000)) }}</div>
            </a>
          </div>
        </div>
      </div>
      <!-- 就业动态 -->
      <div class="dtList">
        <div class="dtitle">
          <span>就业动态</span>
          <img
            @click="toNewList(3)"
            class="dtListImg"
            src="../../assets/images/newlist/more.png"
            alt
          />
        </div>
        <div class="dt4"></div>
        <div class="dtitem">
          <div v-for="(item, index) in newslist4" :key="index">
            <a :href="'https://www.usian.cn/news_detail/' + item.id + '?type=3'" target="_blank">
              <div class="dtitem_dot_box">
                <div class="dtitem_dot"></div>
              </div>
              <div class="pointer dtitem_text">{{ item.title }}</div>
              <div class="pointer">{{ formatDate(new Date(item.createtime * 1000)) }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增 底部 -->
    <!-- 底部 -->
    <bottom_public></bottom_public>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import pinggu from "../../components/pinggu/pinggu";
import bottom_public from "../../components/bottom_public/bottom_public.vue";
import header_public from "../../components/header_public/header_public.vue";
import visualDesignTen from "../../components/indexTen/indexTen";
import xinzilist from "../../components/xinzilist/xinzilist";
import Popup from "../../components/popup/popup.vue";
import Enterprise from "../../components/enterprise/enterprise";
import Video_masks from "../../components/video_masks/video_masks";
import xieyi from "../../components/xieyi/xieyi";
import huanjing from "../../components/huanjing/huanjing";
import "viewerjs/dist/viewer.css";

const axios = require("axios").default;
export default {
  metaInfo: {
    title: "【积云教育官网】IT培训|短视频运营|人工智能|Java培训|AI短视频|计算机培训机构",
    meta: [
      {
        name: "description",
        content:
          " 积云教育是一家提供互联网教育和就业整体解决方案的教育集团，帮助零基础/转行/转岗等人士快速掌握IT技术。旗下开设多个热门产品，通过先进课程体系，优质教研教学、高标准职业规划指导，护航求职路！ ",
      },
      {
        name: "keywords",
        content:
          " 积云教育,北京IT培训,短视频运营,Java培训,Python培训,软件测试,计算机培训机构",
      },
    ],
  },
  //在这里调用ajax请求方法
  created () {
    this.getkcdateList();
    this.getzzkc();
    this.getofferCount(); //offer数量
    this.getofferList(); //就业喜报
    this.getteacherlist(); //获取教师团队列表
    this.getteacherlists(); //获取教师团队列表
    this.getNewslist(); //获取新闻列表

    var linkurl = window.location.host;
    this.linkurl = linkurl;
  },
  data () {
    return {
      itemId: 1,
      linkurl: null,
      moshi: [
        [
          {
            text: "",
          },
          {
            text: "学习基础",
          },
          {
            text: "学习进度",
          },
          {
            text: "信息反馈",
          },
          {
            text: "教 材",
          },
          {
            text: "学习场所",
          },
          {
            text: "方 式",
          },
          {
            text: "目 标",
          },
          {
            text: "标 准",
          },
          {
            text: "讲 师",
          },
          {
            text: "学 员",
          },
          {
            text: "学习成绩",
          },
        ],
        [
          {
            text: "以实际能力为基础",
          },
          {
            text: "以实际表现为依据",
          },
          {
            text: "及时",
          },
          {
            text: "模块/学习包/互联网",
          },
          {
            text: "试学课堂/现场",
          },
          {
            text: "在教师指导下以学员演练为主",
          },
          {
            text: "因材施教",
          },
          {
            text: "职业综合能力",
          },
          {
            text: "学习过程管理者/帮助学员制订学习计划",
          },
          {
            text: "主动学习",
          },
          {
            text: "学员实际掌控能力/师生共同评价",
          },
        ],
        [
          {
            text: "以学科知识为基础",
          },
          {
            text: "以学制教学计划为依据",
          },
          {
            text: "滞后",
          },
          {
            text: "课本",
          },
          {
            text: "课堂",
          },
          {
            text: "理论教学/实验验证",
          },
          {
            text: "共性目标",
          },
          {
            text: "大纲规定的标准",
          },
          {
            text: "传道/授业/解惑/改作业/考试",
          },
          {
            text: "被动学习",
          },
          {
            text: "考试",
          },
        ],
      ],
      offerlist: [],
      bannerList: [],
      zhuanyelist: [
        {
          name: "“短视频+”视觉设计",
          time: "2021.07.23",
        },
        {
          name: "WEB前端云计算",
          time: "2021.07.23",
        },
        {
          name: "Java大数据",
          time: "2021.07.23",
        },
        {
          name: "Python人工智能",
          time: "2021.07.23",
        },
        {
          name: "全栈开发",
          time: "2021.07.23",
        },
      ],
      kclist: [
        {
          img: require("../../assets/images/sy/001.png"),
          title: "WEB前端云计算",
          description:
            "前端就像“衣服”，任何一款产品想要进入大众视野，都必须要有精美的“外衣”或炫酷的特效。无论大小企业，对前端开发的需求都是越来越大。相对于后端开发语言，入门简单，上手容易，即使无基础，你也可以学会Web前端。",
        },
        {
          img: require("../../assets/images/sy/002.png"),
          title: "“短视频”视觉设计",
          description:
            "随着5G技术的发展和应用，短视频已经成为互联网时代最重要的表达方式和传播方式。各行各业都在借助短视频实现转型升级，短视频成为近年来的风口产业，短视频行业人才缺口越来越大，五成以上短视频人才平均年薪超过20万元。",
        },
        {
          img: require("../../assets/images/sy/003.png"),
          title: "Java大数据",
          description:
            "未来是移动互联网+大数据并存的时代，大数据的价值越来越显著，在各个领域的作用也越来越重要。未来企业之间的竞争不再是其他的竞争，而是数据的竞争。大数据平台开发岗位不仅待遇更高，职业生命周期也会更长。",
        },
        {
          img: require("../../assets/images/sy/004.png"),
          title: "全栈开发",
          description:
            "全栈工程师是同时掌握前端开发技术和后端开发技术的人，既能写前端代码，也能写后端代码，能够独立完成前后台的搭建，能利用多种技能独立完成产品。全栈工程师多面手的特长最有可能成为公司技术合伙人，发展前景很大。",
        },
        {
          img: require("../../assets/images/sy/005.png"),
          title: " Python人工智能",
          description:
            "Python毫无疑问已经成为AI人工智能时代开发语言。Python正在迅速成为全球大中小学编程入门课程的教学语言，这种开源动态脚本语言非常有机会在不久的将来成为第一种真正意义上的编程世界语言。",
        },
        {
          img: require("../../assets/images/sy/006.png"),
          title: "技能+学历",
          description:
            "学习36个月课程专业，毕业同时即可获得“国家承认专科学历”，学信网可查。",
        },
      ],
      nengli: [
        {
          img: require("../../assets/images/sy/nengli1.png"),
          text1: "解决问题的能力",
          text2: "解决问题的速度",
          text3: "解决问题的规范",
          text4: "解决问题的技巧",
          btntext: "专业能力",
        },
        {
          img: require("../../assets/images/sy/nengli2.png"),
          text1: "专业能力",
          text2: "就业信心",
          text3: "职业形象",
          text4: "自我表达能力",
          btntext: "面试能力",
        },
        {
          img: require("../../assets/images/sy/nengli3.png"),
          text1: "专业能力",
          text2: "学习能力",
          text3: "沟通能力",
          text4: "抗压能力",
          btntext: "发展能力",
        },
      ],
      menu: [
        "全栈开发",
        "/",
        "大数据",
        "/",
        "人工智能",
        "/",
        "物联网",
        "/",
        "视觉设计",
      ],
      teacherlist: [],
      teacherlists: [],
      xinzilist: [
        {
          year: "2016",
          money: "11059",
        },
        {
          year: "2017",
          money: "12265",
        },
        {
          year: "2018",
          money: "12033",
        },
        {
          year: "2019",
          money: "12531",
        },
        {
          year: "2020",
          money: "13189",
        },
        {
          year: "2021",
          money: "1****",
        },
      ],
      newslist: [],
      newslist2: [],
      newslist3: [],
      newslist4: [],
      zzlist: [],
      bjlist: [],
      kctext: [],
      kcdatelist: [],
      zzkc: [["北京"], ["Java大数据", "AI短视频大师班"]],
      bjkc: [["郑州"], ["Java大数据", "人工智能"]],
    };
  },
  methods: {
    formatDate (now) {
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      // console.log(String(month).length)
      if (String(month).length == 1) {
        // String(month).length
        month = "0" + month;
      }
      if (String(date).length == 1) {
        date = "0" + date;
      }
      return year + "-" + month + "-" + date;
    },
    toNewInfo (id, index) {
      const { href } = this.$router.resolve({
        path: "/news_detail/" + id,
        query: {
          type: index,
        },
      });

      window.open(href, "_blank");
    },
    toNewList (index) {
      const { href } = this.$router.resolve({
        path: "/news_list",
        query: {
          type: index,
        },
      });

      window.open(href, "_blank");
    },

    advice () {
      window.mantis.requestChat();
    },

    //获取offer数量接口
    getofferCount () {
      let year = new Date().getFullYear();
      axios.get("/api/job/getOfferData").then((res) => {
        this.nowoffercount = res.data.data.today_new; //今日offer数量
        this.offerdata = res.data.data.pre_major; //offer各专业列表
        this.nowyear = year;
      });
    },

    //获取就业喜报喜报数据
    getofferList () {
      var limit = 50;
      axios.get("/api/job/getJobList?limit=" + limit).then((res) => {
        this.offerlist = res.data.data; //就业喜报列表
        this.$nextTick(() => {
          this.swiperJob = new Swiper(".swiper-job", {
            slidesPerView: 6,
            direction: "vertical", // 垂直切换选项
            autoplay: {
              delay: 80000,
            }, // 自动播放
            loop: true, // 循环模式选项
          });
        });
      });
    },

    //获取专业教师
    getteacherlist () {
      axios
        .get("/api/block/getItemsByName?name=" + encodeURIComponent("教师团队"))
        .then((res) => {
          // this.teacherlist = res.data.data; //教师团队列表数据
          this.teacherlist = [
            {
              image: require("../../assets/images/sy/ls1.jpg"),
              teacherName: "AI短视频大师班教学总监",
            },
            {
              image: require("../../assets/images/sy/hanCong.png"),
              teacherName: "JAVA大数据教学总监",
            },
            {
              image: require("../../assets/images/sy/liChang.png"),
              teacherName: "JAVA大数据教学总监",
            },
            {
              image: require("../../assets/images/sy/wangNin.png"),
              teacherName: "短视频金牌讲师",
            },
          ];
          this.$nextTick(() => {
            // 教师团队
            this.swiperTeacher && this.swiperTeacher.destroy();
            this.swiperTeacher = new Swiper(".teacher-con", {
              slidesPerView: 4,
              slidesPerGroup: 1,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: false, //修改swiper的父元素时，自动初始化swiper
              autoplay: {
                delay: 1000,
                disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
                stopOnLastSlide: false, // 在最后一页停止
              },
              pagination: {
                el: ".teacher-spit",
                clickable: true,
              },
              loop: true,
            });
          });
        });
    },

    //获取薪资图片
    getteacherlists () {
      // this.teacherlist = res.data.data; //教师团队列表数据
      this.teacherlists = [
        {
          image: require("../../assets/images/sy/index-swiper3.png"),
        },
        {
          image: require("../../assets/images/sy/index-swiper1.png"),
        },
        {
          image: require("../../assets/images/sy/index-swiper4.png"),
        },
        {
          image: require("../../assets/images/sy/index-swiper2.png"),
        },
        {
          image: require("../../assets/images/sy/index-swiper5.png"),
        },
        {
          image: require("../../assets/images/sy/index-swiper6.png"),
        },
      ];

      this.$nextTick(() => {
        new Swiper(".bjphone", {
          slidesPerView: 3,

          // autoplay: {
          // 	delay: 2000,
          // 	disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
          // 	stopOnLastSlide: false, // 在最后一页停止
          // },
          centeredSlides: true,
          navigation: {
            nextEl: ".success-button-next",
            prevEl: ".success-button-prev",
          },
          loop: true,
        });
      });
    },

    //新闻列表
    getNewslist () {
      axios.get("/api/block/getArchivesList?limit=6&catid=18").then((res) => {
        this.newslist = res.data.data;
        // console.log(this.newslist)
        // console.log(res.data.data.seotitle)
      });
      axios.get("/api/block/getArchivesList?limit=6&catid=20").then((res) => {
        this.newslist2 = res.data.data;
        // console.log(res)
      });
      axios.get("/api/block/getArchivesList?limit=6&catid=21").then((res) => {
        this.newslist3 = res.data.data;
        // console.log(res)
      });
      axios.get("/api/block/getArchivesList?limit=6&catid=22").then((res) => {
        this.newslist4 = res.data.data;
        // console.log(res)
      });
    },
    hoverItem (id) {
      this.itemId = id;
    },
    outItem () {
      this.itemId = "0";
    },
    tojava () {
      this.$router.push("/big_data");
    },
    todesgin () {
      // this.$router.push("/visual_design");
    },
    toquanzhan () {
      this.$router.push("/fullstack");
    },
    toyunjisuan () {
      this.$router.push("/calculationPage");
    },
    topython () {
      this.$router.push("/intelligence");
    },
    toceshi () {
      // 目前不跳转
    },
    getDimComb (doubleList) {
      if (doubleList.length == 0) return []; //先return掉空的

      const result = []; //最终组合集
      /**
       * doubleList 二维数组 Array
       * index 当前所在步骤
       * currentList 当前累积维度的数组
       */
      const _back = (doubleList, index, currentList) => {
        if (index >= doubleList.length) {
          result.push([...currentList]);
        } else {
          doubleList[index].forEach((item) => {
            currentList.push(item);
            _back(doubleList, index + 1, currentList);
            currentList.pop();
          });
        }
      };
      _back(doubleList, 0, []);

      console.log(result);

      return result;
    },
    getzzkc () {
      var zzkctext = this.zzkc;
      var bjkctext = this.bjkc;

      var zzlist = this.getDimComb(zzkctext);
      var bjlist = this.getDimComb(bjkctext);

      this.zzlist = zzlist;
      this.bjlist = bjlist;

      this.kctext = this.gettextList().concat(this.getbjtextList());
    },
    gettextList () {
      var kctextarr = [];
      var kctextlist = [];
      kctextarr[0] = this.zzlist;
      // kctextarr[0]= this.bjlist
      kctextarr[1] = this.kcdatelist;

      var aaaa = this.getDimComb(kctextarr);

      aaaa.forEach((item) => {
        item = item.flat(Infinity);
        kctextlist.push(item);
      });

      return kctextlist;
    },
    getbjtextList () {
      var kctextarr = [];

      var kctextlist = [];

      kctextarr[0] = this.bjlist;
      // kctextarr[0]= this.bjlist
      kctextarr[1] = this.kcdatelist;

      var aaaa = this.getDimComb(kctextarr);

      aaaa.forEach((item) => {
        item = item.flat(Infinity);

        kctextlist.push(item);
      });

      return kctextlist;
    },
    getLocalTime (nS) {
      return new Date(parseInt(nS)).toLocaleString();
    },
    zeroize (obj) {
      if (obj < 10) {
        return "0" + obj;
      } else {
        return obj;
      }
    },
    getkcdateList () {
      var now = new Date();
      var nowdate = now.getTime();
      var fullyear = now.getFullYear();
      var fullmonth = this.zeroize(now.getMonth() + 1);
      var currentLastDay = fullyear + "-" + fullmonth + "-29";

      var size = 8;
      var kcarr = [];

      for (var i = 0; i < size; i++) {
        var date =
          new Date(currentLastDay).getTime() + 24 * 60 * 60 * 1000 * i * 7;
        var curdate = new Date(date);

        var monthnum = this.zeroize(curdate.getMonth() + 1);
        var daynum = this.zeroize(curdate.getDate());
        var t = monthnum + "-" + daynum;

        if (curdate.getTime() > nowdate) {
          kcarr.push(t);
        }
      }

      this.kcdatelist = kcarr;
    },
  },
  components: {
    Enterprise,
    bottom_public,
    header_public,
    Video_masks,
    Popup,
    pinggu,
    xinzilist,
    xieyi,
    huanjing,
    visualDesignTen,
  },
  mounted () {
    var mySwiperbanner = new Swiper(".mybanner", {
      autoplay: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    var mySwiper = new Swiper(".kktime", {
      direction: "vertical",
      slidesPerView: "auto",
      autoplay: {
        delay: 800, //1秒切换一次
      },
      slidesPerColumn: 2,
      slidesPerColumnFill: "column",
    });
  },
};
</script>
<style lang="scss" scoped>
@import url("../../assets/css/index.css");

.img-scale {
  cursor: pointer;
  transform: scale(1.1);
}

.flex_public {
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.width_100 {
  width: 100% !important;
}

.color_white {
  color: #ffffff;
}

.color_grey {
  color: #898989;
}

.font-size16 {
  font-size: 1.33rem;
}

.text-align {
  text-align: left;
}

.news_con .text-align a {
  color: #666666;
}

.border_left {
  border-left: 1px solid rgba(240, 240, 240, 1);
}

.star_con,
.star_conTex {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;

  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
}

.star {
  width: 100%;
  height: 82vh;
  // background: url("~@/assets/images/bannerone.jpg") no-repeat fixed center;  bannernew1
  // background: url("~@/assets/images/bannernew1.jpeg") no-repeat fixed center;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  // background-attachment: scroll;
  /* 老版本的 Firefox */
  // -webkit-justify-content: center;
  // -moz-justify-content: center;
  // -ms-justify-content: center;
  // -o-justify-content: center;
  // justify-content: center;

  // -webkit-align-items: center;
  // -moz-align-items: center;
  // -ms-align-items: center;
  // -o-align-items: center;
  // align-items: center;
  position: relative;
}

.banner-pagination {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-pagination .banner-spot {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  margin: 0 4px;
  opacity: 1;
}

.banner-pagination .spot-change {
  background: red;
  opacity: 1;
}

.swiper-banner .swiper-slide {
  position: relative;
}

.swiper-banner .swiper-slide a {
  width: 100%;
  height: 88%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
}

.swiper-banner .swiper-slide,
.swiper-banner .swiper-slide img {
  width: 100%;
  height: 100%;
  // background: url("~@/assets/images/bannernew1.jpg") no-repeat fixed center;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  // background-attachment: scroll;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.swiper-banner-img {
  width: 100%;
  height: 100%;
}

.banner_bf {
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 111111;
}

.banner_bf video {
  width: 50%;
  height: 60vh;
}

.star_con {
  width: 62.5vw;
  height: 23.22vw;
  color: $usian-text-color-inverse;
  padding-top: 40px;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.star_con h1 {
  font-size: 4.062rem;
  font-family: Heiti SC;
  letter-spacing: 0.375rem;
  text-shadow: 0px 7px 3px rgba(0, 0, 0, 0.47);
}

.star_conTex {
  font-size: 1.25rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  letter-spacing: 3px;
  text-shadow: 0px 6px 3px rgba(0, 0, 0, 0.47);
}

.star_conTex p {
  line-height: 2vw;
}

.details {
  /*width: 10.833vw;*/
  /*height: 3.5416vw;*/
  font-size: 1.525rem;
  font-family: SimHei;
  line-height: 3.5416vw;
  font-weight: 400;
  background: rgba(221, 54, 72, 1);
  border-radius: 7px;
  margin-right: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.salary {
  /*width: 6.614vw;*/
  /*height: 3.541vw;*/
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  /*line-height: 68px;*/
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;

  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;

  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.salary span {
  font-size: 1.525rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  vertical-align: middle;
}

.salary div {
  width: 1.354vw;
  height: 1.354vw;
  vertical-align: middle;
  margin-left: 0.5vw;
}

.salary div img {
  width: 100%;
  height: 100%;
  display: block;
}

.middle_con {
  width: 62.5vw;
  margin: auto;
}

.offer {
  width: 100%;
  margin: 20px 0;
}

.offer ul {
  width: 100%;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;

  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.offer ul li {
  width: 7.333vw;
  height: 6.25vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;

  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;
  margin-top: 10px;
  position: relative;
}

.offer ul li a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.entry {
  justify-content: space-around !important;
}

.offer ul li p {
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(103, 107, 129, 1);
}

.offer ul li span {
  font-size: 2.4rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(8, 15, 77, 1);
}

.entry p {
  font-size: 1.8rem !important;
  color: #f30733 !important;
}

.entry span {
  font-size: 1rem !important;
  color: #080f4d !important;
}

.color_impo {
  color: #ff4b36 !important;
}

.averageSalary work_con,
.good_news,
.campus_ambient,
.campus_ambientCon,
.ambientCon_top,
.movies,
.works,
.works_tex,
.works_con,
.live_broadcast,
.pattern,
.service {
  width: 100%;
}

.top_text span {
  font-size: 14px;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(114, 114, 114, 1);
}

.top_text h5 {
  font-size: 2rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(28, 26, 91, 1);
  margin: 0.8rem auto;
}

.bottom_text {
  position: relative;
}

.bottom_text,
.averageSalary {
  margin: 3.125vw auto 50px;
}

.top_text {
  margin: 4rem 0 2.6rem;
}

.news .top_text {
  margin-bottom: 1.25rem;
}

.last_li {
  position: relative;
}

.major-box {
  width: 11.5vw !important;
  // width: 12.5vw !important;
  height: 13.2291vw !important;
  margin-right: 1.05vw;
  overflow: hidden;
}

.interview_video {
  width: 14.84375vw;
  height: 11.0416vw;
  // position: relative;
}

.interview_video img {
  width: 10%;
  height: 100%;
}

.major-box img {
  width: 100%;
  height: 13.2291vw;
  transition: all 2s;
  /*图片放大过程的时间*/
  position: relative;
}

.major-box img:hover {
  cursor: pointer;
  transform: scale(1.1);
  /*以y轴为中心旋转*/
}

.averageSalary ul,
.averageSalary ul li {
  height: 14.6041vw;
}

.averageSalary ul:first-child li:nth-child(1) {
  border: 1px solid #f0f0f0;
}

.averageSalary ul:first-child li:nth-child(2) {
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.averageSalary ul:first-child li:nth-child(3) {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.averageSalary ul:last-child li:nth-child(1) {
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.averageSalary ul:last-child li:nth-child(2),
.averageSalary ul:last-child li:nth-child(3),
.averageSalary ul:last-child li:nth-child(4) {
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.averageSalary ul:first-child li:nth-child(2):hover,
.averageSalary ul:first-child li:nth-child(3):hover {
  border-left: 1px solid #f0f0f0;
}

.averageSalary ul:last-child li:nth-child(1):hover,
.averageSalary ul:last-child li:nth-child(2):hover,
.averageSalary ul:last-child li:nth-child(3):hover,
.averageSalary ul:last-child li:nth-child(4):hover {
  border-top: 1px solid #f0f0f0;
}

.averageSalary ul li:hover {
  transform: translateY(-20px);
  transition: all 0.5s;
}

.averageSalary ul:last-child li > div {
  height: 2.604vw;
  background: #222025;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 0.72vw;
  cursor: pointer;
}

.averageSalary ul:last-child li > div > span {
  font-size: 1rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  vertical-align: middle;
}

.averageSalary ul:last-child li > div > img {
  width: 1.4vw;
  height: 1.4vw;
  vertical-align: middle;
  margin-top: 0.15vw;
  margin-left: 0.2vw;
}

.first_li {
  width: 31.25vw !important;
  background-color: rgb(249, 249, 249) !important;
}

.alary_list {
  flex-flow: row nowrap;
}

.alary_list li {
  width: 15.625vw;
  text-align: left;
  padding: 0 1rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 1);
  // border: 1px solid rgba(240, 240, 240, 1);
  box-sizing: border-box;
}

.alary_list li span {
  font-size: 0.875rem;
  font-weight: bold;
  color: rgba(215, 7, 47, 1);
}

.alary_list li p {
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}

.alary_list li i {
  color: #d7072f;
  font-weight: bold;
}

.first_li span {
  color: black !important;
  line-height: 2rem !important;
}

.first_li p {
  font-size: 3rem !important;
}

.chart_con {
  position: relative;
}

.decorate_img {
  height: 1.145vw;
  position: relative;
}

.decorate_img img {
  width: 13.541vw;
  height: 1.145vw;
  position: absolute;
  bottom: 0;
  right: 12vw;
}

.tabe_box {
  font-size: 1.375rem;
  font-family: PingFang SC;
  background: #fff;
}

.swiper-job {
  height: 24.96vw;
}

.good_news_slide {
  height: 3.33vw !important;
  background: rgba(249, 249, 249, 1);
}

.good_news_wrapper .b {
  // height: 0.83vw;
  background-color: #ffffff;
}

.tabe_box div span {
  display: inline-block;
  width: 20%;
}

.table_header {
  height: 3.645vw !important;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background: #dd3648 !important;
  box-shadow: 0px 14px 43px 0px rgba(211, 211, 211, 0.25);
  // margin-bottom: 1.833vw !important;
}

.font_weight {
  font-weight: bold;
  color: #d7072f !important;
}

.more {
  text-align: right;
  padding-bottom: 0.5rem;
}

.more a {
  display: inline-block;
  font-size: 0.875rem;
  text-align: center;
  line-height: 2rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: #0f0c3e;
  border-radius: 11px;
  box-sizing: border-box;
  padding: 0.4rem 1rem;
  font-family: PingFang SC;
}

.new-more {
  margin-bottom: 1vw;
}

.campus_ambientCon {
  position: relative;
}

.more-campus {
  font-size: 0.875rem;
  text-align: center;
  /*line-height: 2rem;*/
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: #0f0c3e;
  border-radius: 11px;
  box-sizing: border-box;
  padding: 0.4rem 1rem;
  position: absolute;
  right: 0;
  top: -2.8vw;
}

.head_style {
  height: 3.645vw !important;
  font-size: 1.375rem;
  font-family: PingFang SC;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background: #dd3648;
  box-shadow: 0px 14px 43px 0px rgba(211, 211, 211, 0.25);
}

table {
  border-collapse: collapse;
}

.color_red {
  color: #d7072f !important;
}

.ambientCon_top {
  margin-bottom: 32px;
}

.ambientCon_top img,
.topImgCon_topLe div,
.topImgCon_topRi div {
  border-radius: 8px 8px 8px 0px;
}

.ambientCon_topTex,
.ambientCon_topImg,
.ambientCon_topImgCon {
  width: 20.312vw;
  height: 20.312vw;
  background-color: #fff;
  border-radius: 8px 8px 8px 0px;
  overflow: hidden;
}

.ambientCon_top {
  justify-content: space-between;
}

.ambientCon_topTex {
  flex-flow: column nowrap;
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
  padding-left: 2.1875vw;
}

.ambientCon_topTex h5 {
  font-size: 1.6rem;
  line-height: 4.166vw;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(32, 32, 32, 1);
}

.ambientCon_topTex p {
  font-size: 1rem;
  line-height: 1.666vw;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.ambientCon_topImgCon {
  flex-flow: column nowrap;
  justify-content: space-between;
}

.ambientCon_topImg img,
.topImgCon_bot img {
  width: 100%;
  height: 100%;
  transition: all 2s;
  position: relative;
}

.ambientCon_topImg img:hover,
.topImgCon_bot img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.topImgCon_top,
.topImgCon_bot {
  width: 100%;
  height: 9.375vw;
}

.topImgCon_topLe,
.topImgCon_topRi,
.topImgCon_bot {
  position: relative;
  overflow: hidden;
}

.topImgCon_topLe,
.topImgCon_topRi {
  width: 9.375vw;
}

.topImgCon_topLe div,
.topImgCon_topRi div {
  width: 100%;
  height: 25%;
  font-size: 1.25rem;
  font-weight: 800;
  // line-height: 15vw;
  color: rgba(255, 255, 255, 1);
  // background: rgba(0, 0, 0, 1);
  // opacity: 0.36;
  position: absolute;
  // top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.topImgCon_bot p {
  font-size: 1.25rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: 10px;
  left: 14px;
}

.topImgCon_top {
  justify-content: space-between;
}

.topImgCon_topLe img,
.topImgCon_topRi img {
  width: 100%;
  height: 100%;
  transition: all 2s;
  position: relative;
}

.topImgCon_topLe img:hover,
.topImgCon_topRi img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.topImgCon_bot img {
  width: 100%;
  height: 100%;
}

.movies {
  margin: 60px 0;
}

.movies_video {
  width: 100%;
  height: 23.333vw;
  justify-content: space-between;
}

.movies_videoLe {
  width: 41.614vw;
  height: 23.333vw;
  position: relative;
}

.anniu,
.anniu_small,
.anniu_smallBo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.anniu_small,
.anniu_smallBo {
  height: 11.64vw;
}

.anniu img {
  width: 5.937vw !important; //114px
  height: 5.937vw !important; //114px
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.movies_videoLe img,
.movies_videoRiTo img,
.movies_videoRiBo img {
  width: 100%;
  height: 100%;
}

.movies_decorate,
.movies_decorateSmall {
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  bottom: 6px;
  left: 12px;
}

.movies_decorate span,
.movies_decorateSmall span {
  display: block;
  // width: 3.031vw; //39px
  // height: 1.5vw; //23px
  font-size: 0.7rem;
  line-height: 1.5vw;
  color: $usian-bg-color;
  background: rgba(38, 38, 38, 1);
  padding: 0.1vw 0.5vw;
}

.movies_decorate h4,
.movies_decorateSmall h4 {
  font-size: 1.75rem;
  font-weight: bold;
  color: $usian-bg-color;
  margin: 4px 0 6px 0;
}

.movies_decorateSmall h4 {
  font-size: 1.25rem;
}

.movies_decorate p,
.movies_decorateSmall p {
  font-size: 0.875rem;
  font-weight: bold;
  color: $usian-bg-color;
}

.anniu_small img,
.anniu_smallBo img {
  width: 1.718vw;
  height: 1.718vw;
  position: absolute;
  top: 20px;
  right: 20px;
}

.movies_videoRi {
  width: 20.765vw; //398.7px
  height: 100%;
  // position: relative;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.movies_videoRiTo,
.movies_videoRiBo {
  width: 100%;
  height: 11.64vw; //223.5px
  position: relative;
}

.interview {
  width: 100%;
  height: 4.906vw;
  font-size: 1.125rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 4.906vw;
}

.swiper-video {
  width: 15.625vw !important;
  height: 15.625vw !important;
  /*margin-right: 10px!important;*/
}

.interview_videoTop {
  width: 15.3vw;
  height: 8.177vw;
  position: relative;
}

.interview_videoTop img {
  width: 100%;
  height: 100%;
}

.mask {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0.5vw;
  right: 1vw;
}

.mask img {
  width: 2.447vw;
  height: 2.447vw;
}

.interview_videoBot {
  height: 2.864vw;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 10px;
}

.interview_videoBot p {
  font-size: 1rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 16px;
}

.interview_videoBot span {
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(148, 148, 148, 1);
}

.works_tex {
  height: 3.125vw;
  margin-bottom: 20px;
  justify-content: center;
}

.works_texList {
  width: 66%;
  align-items: center;
}

.works_texList li {
  flex: 1;
  font-size: 1rem;
  font-weight: bold;
  color: #777777;
  cursor: pointer;
}

.works_texList_color {
  color: #090e71 !important;
}

.works_conDoubleLi,
.works_conThreeBox {
  width: 19.473vw;
}

.bf {
  width: 5.9375vw !important;
  height: 5.9375vw !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bf img {
  width: 100%;
  height: 100%;
}

.bf_x {
  width: 3.354vw;
  height: 3.354vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bf_x img {
  width: 100%;
  height: 100%;
}

.works_conDoubleLiImg,
.works_conDoubleRi_img,
.works_conThreeImg {
  width: 100%;
  height: 21.401vw;
}

.works_conDoubleRi_img {
  position: relative;
}

.works_conDoubleLi img,
.works_conDoubleRi img,
.works_conThreeImg img {
  width: 100%;
  height: 100%;
}

.works_conThreeImg {
  position: relative;
}

.works_conDoubleLi_tex,
.works_conDoubleRi_tex,
.works_conThreeTex {
  height: 3.645vw;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 10px;
}

.works_conDoubleLi_tex p,
.works_conDoubleRi_tex p,
.works_conThreeTex p {
  font-size: 1.375rem;
  font-weight: 800;
  color: rgba(22, 21, 26, 1);
}

.works_conDoubleLi_tex span,
.works_conDoubleRi_tex span,
.works_conThreeTex span {
  font-size: 0.9375rem;
  font-weight: 800;
  color: rgba(22, 21, 26, 1);
  opacity: 0.5;
}

.works_conDoubleRi {
  width: 40.937vw;
}

.works_conDouble,
.works_conThree,
.live_broadcastBox {
  margin-bottom: 40px;
  justify-content: space-between;
}

.live_broadcastBox {
  height: 17.02vw;
  position: relative;
}

.img-con-slide {
  width: 19.2708vw !important;
  height: 13.02vw !important;
  margin: 0 0.8vw;
  cursor: pointer;
}

.img-con-slide img {
  width: 100%;
  height: 100%;
}

.live_broadcast {
  position: relative;
}

.switch {
  width: 6.25vw !important;
  height: 2.604vw !important;
  flex-flow: row nowrap !important;
  justify-content: space-between !important;
  position: absolute !important;
  top: -3.5vw !important;
  right: 0 !important;
}

.switch img {
  width: 2.604vw !important;
  height: 100% !important;
  outline: none;
}

.pattern_img {
  height: 19.479vw;
}

.pattern_img img {
  width: 100%;
  height: 100%;
}

.service_list ul {
  flex-flow: row wrap;
  justify-content: space-between;
}

.service_list ul li {
  width: 18.229vw;
  height: 17.08vw;
  margin-bottom: 70px;
  cursor: pointer;
}

.service_list ul li:hover {
  transform: translateY(-20px);
  transition: all 0.5s;
}

.service_list ul li img {
  width: 100%;
  height: 100%;
}

.news_img {
  margin-bottom: 14px;
}

.news_img ul {
  height: 13.02vw;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.news_img ul li {
  width: 19.27vw;
  height: 100%;
}

.news_img ul li a {
  display: inline-block;
  overflow: hidden;
}

.news_img ul li a img,
.news_img ul li a {
  width: 100%;
  height: 100%;
}

.news_text ul {
  height: 8.916vw;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.news_text ul li {
  width: 19.0625vw;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
}

.news_text ul li > div img {
  margin-right: 8px;
}

.news_text .max-two-line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news_con {
  flex-flow: row nowrap;
  /*justify-content: space-between;*/
  align-items: center;
  margin: 8px auto;
}

.news_con p {
  font-size: 1.25rem;
  font-weight: 800;
}

.news_con div {
  width: 2.864vw;
  height: 2.864vw;
  border-radius: 6px;
  flex-flow: column nowrap;
  justify-content: center;
  background: linear-gradient(
    109deg,
    rgba(253, 44, 187, 1),
    rgba(131, 6, 241, 1)
  );
  margin-right: 0.6vw;
}

.news_con div p:first-child {
  font-size: 1.125rem;
}

.news_con div p:last-child {
  font-size: 0.75rem;
}

.swiper-major {
  height: 17.625vw;
}

.video_swiper_box {
  position: relative;
}

.major-swith-next,
.major-swith-prev,
.teachers-switch-next,
.teachers-switch-prev,
.video-switch-next,
.video-switch-prev {
  border-radius: 50%;
  width: 2.604vw;
  height: 2.604vw;
  background-color: #000000;
  opacity: 1 !important;
}

.major-swith-next,
.major-swith-prev,
.teachers-switch-next,
.teachers-switch-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 45% !important;
}

.major-swith-next,
.major-swith-prev {
  top: 42% !important;
}

.video-switch-next,
.video-switch-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 32% !important;
}

.swiper-button-next:after,
.swiper-button-prev:after,
.teachers-switch-next:after,
.teachers-switch-prev:after,
.video-switch-next:after,
.video-switch-prev:after {
  font-size: 1.125rem;
}

.major-spit {
  --swiper-theme-color: #cacaca;
  --swiper-pagination-color: #e11e2c;
  /* 两种都可以 */
}

.teacher-con {
  height: 31.25rem !important;
}

.teachers_boxs {
  width: 15.625vw !important;
  height: 16.625vw !important;
  position: relative;
  border-radius: 5px;
  margin-right: 0 !important;
}

.success-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 16px;
  z-index: 10;
  background-image: url(../../assets/images/sy/left-btn.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.success-button-next {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  right: 16px;
  z-index: 10;
  background-image: url(../../assets/images/sy/right-btn.png);
  background-size: cover;
}

.teachers_box a {
  display: inline-block;
  width: 92%;
  height: 100%;
  overflow: hidden;
}

.teachers_box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: all 2s;
  /*图片放大过程的时间*/
  position: relative;
}

.teachers_box a img:hover {
  cursor: pointer;
  transform: scale(1.1);
  /*以y轴为中心旋转*/
}

.teacherText {
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: absolute;
  bottom: 0px;
  background: #072839;
  font-size: 21px;
  text-align: center;
  color: #ffffff;
}

.teacher_team_con {
  width: 100%;
  height: 16%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0px 14px 4px;
}

.teacher_zz {
  width: 91% !important;
  margin: auto;
  background-color: #000;
  opacity: 0.5;
}

.major-con {
  height: 20% !important;
  //  background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
  background-color: #000;
  opacity: 0.5;
  padding-bottom: 5px !important;
}

.team_con_top span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 3px 5px 4px #000000;
}

.team_con_top img {
  width: 1.666vw !important;
  height: 1.666vw !important;
}

.team_con_bottom p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 2vw;
}

.team_con_bottom span {
  font-size: 0.875rem;
  font-weight: 600;
  color: #ffffff;
}

.flex_style {
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.direction_row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.direction_column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.flex_wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex_nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.align_flex-start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
}

.align_center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.align_end {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
}

.justify_start {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
}

.justify_end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
}

.justify_center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

.justify_around {
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;
}

.justify_between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

a {
  color: #ffffff;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active {
    -webkit-tap-highlight-color: transparent;
  }
}

.text_left {
  text-align: justify !important;
}

.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.videomasks {
  max-width: 62vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.videomasks video {
  width: 100%;
  height: 100%;
}

.bannerWrapBg {
  width: 100%;
  height: 830px;
  background-image: url(../../assets/images/sy/swiper-bg.jpg);
  background-repeat: no-repeat;
  background-size: 1920px 810px;
}

.bannerWrapImg {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1130px) {
}

@media screen and (max-width: 1085px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1004px) {
}
</style>
